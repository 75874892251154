import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginAccessGuard } from './guards/loginaccess.guard';
import { BlockresetpageGuard } from './guards/blockresetpage.guard';
import { BlockotpverificationGuard } from './guards/blockotpverification.guard';

const routes: Routes = [
  // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

  { path: 'login', loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule), canActivate: [LoginAccessGuard] },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule), canActivate: [AuthGuard] },
  { path: 'dashboard/:id', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule), canActivate: [AuthGuard] },

  // { path: 'maintenance/domains', loadChildren: () => import( './maintenance/domains/domains.module').then(m => m.DomainsPageModule), canActivate: [AuthGuard]
  //  },
  // { path: 'maintenance/domains/:id', loadChildren: () => import( './maintenance/domains/domain/domain.module').then(m => m.DomainPageModule), canActivate: [AuthGuard]},

  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule), canActivate: [AuthGuard] },
  { path: 'mainsettings', loadChildren: () => import('./main-settings/main-settings.module').then(m => m.MainSettingsPageModule), canActivate: [AuthGuard]},
  { path: 'data', loadChildren: () => import('./data/data.module').then(m => m.DataPageModule), canActivate: [AuthGuard] },
  { path: 'maintenance', loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenancePageModule), canActivate: [AuthGuard] },
  { path: 'data/users', loadChildren: () => import('./maintenance/users/users.module').then(m => m.UsersPageModule), canActivate: [AuthGuard] },
  { path: 'data/users/:id', loadChildren: () => import('./maintenance/users/user-detail/user-detail.module').then(m => m.UserPageModule), canActivate: [AuthGuard] },
  // { path: 'maintenance/locales', loadChildren: () => import( './maintenance/locales/locales.module').then(m => m.LocalesPageModule), canActivate: [AuthGuard], data: {
  //     allowedRoles: [],
  //     allowedDomains: ['master'],
  //   } },
  // { path: 'maintenance/locales/:id', loadChildren: () => import( './maintenance/locales/locale/locale.module').then(m => m.LocalePageModule), canActivate: [AuthGuard], data: {
  //     allowedRoles: [],
  //     allowedDomains: ['master'],
  //   } },
  // { path: 'maintenance/notificationtemplates', loadChildren: () => import( './maintenance/notification-templates/notification-templates.module').then(m => m.NotificationTemplatesPageModule), canActivate: [AuthGuard] },
  // { path: 'maintenance/notificationtemplates/:id', loadChildren: () => import( './maintenance/notification-templates/notification-template/notification-template.module').then(m => m.NotificationTemplatePageModule), canActivate: [AuthGuard] },
  // { path: 'maintenance/permissions', loadChildren: () => import( './maintenance/permissions/permissions.module').then(m => m.PermissionsPageModule), canActivate: [AuthGuard] },
  { path: 'mainsettings/settings', loadChildren: () => import('./maintenance/settings/settings.module').then(m => m.SettingsPageModule), canActivate: [AuthGuard] },

  // { path: 'maintenance/messages', loadChildren: () => import( './maintenance/messages/messages.module').then(m => m.MessagesPageModule), canActivate: [AuthGuard] },
  // { path: 'maintenance/messages/:id', loadChildren: () => import( './maintenance/messages/message/message.module').then(m => m.MessagePageModule), canActivate: [AuthGuard]  },
  { path: 'mainsettings/systemtranslations', loadChildren: () => import('./maintenance/systemtranslations/systemtranslations.module').then(m => m.SystemtranslationsPageModule), canActivate: [AuthGuard] },
  { path: 'mainsettings/systemtranslations/:id', loadChildren: () => import('./maintenance/systemtranslations/systemtranslation/systemtranslation.module').then(m => m.SystemtranslationPageModule), canActivate: [AuthGuard] },
  // { path: 'messages', loadChildren: () => import( './messages/messages.module').then(m => m.MessagesPageModule), canActivate: [AuthGuard] },
  { path: 'forgot-password', loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'forgot-password/:id', loadChildren: () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule) },
  // { path: 'registration', loadChildren: () => import( './auth/registration/registration.module').then(m=> m.RegistrationPageModule) },
  { path: 'loginverification', loadChildren: () => import('./auth/otpverification/otpverification.module').then(m => m.OtpverificationPageModule) },
  { path: 'resetdefaultpassword', loadChildren: () => import('./auth/reset-defaultpassword/reset-defaultpassword.module').then(m => m.ResetDefaultpasswordPageModule), canActivate: [BlockresetpageGuard] },

  { path: 'verifyemail/:id', loadChildren: () => import('./verifyemail/verifyemail.module').then(m => m.VerifyemailPageModule) },
  

  { path: 'EmaillinkAuthentication/:id', loadChildren: () => import('./auth/login/login-onetime-callback/login-onetime-callback.module').then(m => m.LoginOnetimeCallbackPageModule) },
  { path: 'login-invalid', loadChildren: () => import('./auth/login/login-onetime-invalid/login-onetime-invalid.module').then(m => m.LoginOnetimeInvalidPageModule), canActivate: [LoginAccessGuard] },
  { path: 'sso_landing', loadChildren: () => import('./sso/sso-landing/sso-landing.module').then(m => m.SsoLandingPageModule) },
  { path: 'sso_callback', loadChildren: () => import('./sso/sso-callback/sso-callback.module').then(m => m.SsoCallbackPageModule) },
  { path: 'mainsettings/emailtemplates', loadChildren: () => import('./maintenance/email-templates/email-templates.module').then(m => m.EmailTemplatesPageModule), canActivate: [AuthGuard] },
  { path: 'mainsettings/emailtemplates/:id', loadChildren: () => import('./maintenance/email-templates/email-template/email-template.module').then(m => m.EmailTemplatePageModule), canActivate: [AuthGuard] },

  { path: 'data/customers', loadChildren: () => import('./maintenance/customers/customers.module').then(m => m.CustomerPageModule), canActivate: [AuthGuard] },
  { path: 'data/customers/:id', loadChildren: () => import('./maintenance/customers/customer-detail/customer-detail.module').then(m => m.CustomerPageModule), canActivate: [AuthGuard] },
  { path: 'mainsettings/files', loadChildren: () => import('./maintenance/files/files.module').then(m => m.FilePageModule), canActivate: [AuthGuard] },
  { path: 'mainsettings/files/:id', loadChildren: () => import('./maintenance/files/file-detail/file-detail.module').then(m => m.FilePageModule), canActivate: [AuthGuard] },
  { path: 'data/locationmaintenance', loadChildren: () => import('./maintenance/locationmaintenance/locationmaintenance.module').then(m => m.LocationMaintenancePageModule), canActivate: [AuthGuard] },
  { path: 'data/locations/:locationId/maintenances/:locationMaintenanceId', loadChildren: () => import('./maintenance/locationmaintenance/locationmaintenancedetail/locationmaintenancedetail.module').then(m => m.LocationMaintenanceDetailPageModule), canActivate: [AuthGuard] },
  { path: 'data/locations', loadChildren: () => import('./maintenance/locations/locations.module').then(m => m.LocationsPageModule), canActivate: [AuthGuard] },
  { path: 'data/locations/:id', loadChildren: () => import('./maintenance/locations/location-detail/location-detail.module').then(m => m.LocationDetailPageModule), canActivate: [AuthGuard] },
  { path: 'reservations', loadChildren: () => import('./reservations/reservations.module').then(m => m.ReservationsPageModule), canActivate: [AuthGuard] },
  { path: 'reservations/:id', loadChildren: () => import('./reservations/reservation-detail/reservation-detail.module').then(m => m.ReservationDetailPageModule), canActivate: [AuthGuard] },

  { path: 'data/vehicles', loadChildren: () => import('./maintenance/vehicles/vehicles.module').then(m => m.VehiclesPageModule), canActivate: [AuthGuard] },
  { path: 'data/vehicles/:id', loadChildren: () => import('./maintenance/vehicles/vehicle-detail/vehicle-detail.module').then(m => m.VehiclePageModule), canActivate: [AuthGuard] },
  { path: 'email/reservations/:reservationCode', loadChildren: () => import('./reservations/reservation-order/reservation-order.module').then( m => m.ReservationOrderPageModule)},
  { path: '**', redirectTo: 'reservations' },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
